<template>
    <div class="content">
      <notice />
      <div class="fillingArea flex">
          <div class="area">
            <el-form label-position="top" :model="userForm">
              <el-form-item label="用户名" prop="name">
                <div class="input"><el-input v-model="userForm.name" disabled></el-input></div>
              </el-form-item>
              <el-form-item label="手机号" prop="phone">
                <div class="input"><el-input v-model="userForm.phone" disabled></el-input></div>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="email">
                <div class="input"><el-input v-model="userForm.email" disabled></el-input></div>
              </el-form-item>
            </el-form>
          </div>
          <div class="area">
            <div class="areaTitle">您选择了套餐A：<span>￥2566</span></div>
            <div class="desc">
                <div class="descTitle">套餐说明</div>
                <div class="descContent">伟大时代孕育伟大思想，伟大思想引领伟大实践。3月1日，新华社国家高端智库发布《迈向现代化强国的发展密码——习近平经济思想的时代特质和实践价值》报告，并举行主题研讨会。中共十八大以来，面对严峻复杂的国际形势和艰巨繁重的国内改革发展稳定任务，以习近平同志为核心的党中央高瞻远瞩、统揽全局、把握大势，提出一系列治国理政新理念新思想新战略，引领中国经济发展取得历史性成就、发生历史性变革，在实践中形成和发展了习近平经济思想。</div>
            </div>
            <div class="button">
              <el-button type="primary" class="buttonClass" @click="submitForm('userForm')">我确认操作退款</el-button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import notice from '../components/Notice.vue'

export default {
  name: 'drawback',
  components: {
    notice
  },
  data() {
    return {
      userForm: {
        phone: '',
        name: '',
        email: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    border: 1px solid #e3e3e3;
    .fillingArea {
      margin-top: 10px;
      width: 100%;
      .area {
        flex: 1;
        .input {
          width: 450px;
        }
        .areaTitle {
          font-size: 14px;
          line-height: 40px;
          padding-bottom: 10px;
          span {
            font-weight: bold;
            color: #409EFF;
          }
        }
        .desc {
          .descTitle {
            font-size: 18px;
          }
          .descContent {
            margin-top: 10px;
            height: 165px;
            overflow-y: auto;
            line-height: 20px;
          }
        }
        .button {
          margin: 22px 0;
          .buttonClass {
            width: 400px;
            height: 48px;
          }
        }
      }
    }
  }
  .steps {
    .pencil {
        width: 70px;
        height: 8px;
        border-radius: 4px;
        background-color: #dcdcdc;
        margin-right: -5px;
    }
    .circle {
        width: 18px;
        height: 18px;
        background-color: #dcdcdc;
        border-radius: 50%;
    }
    span {
        font-size: 14px;
        margin-left: 4px;
        color: #dcdcdc;
    }
    .step {
        margin-right: 10px;
        &:first-child {
        .pencil, .circle {
            background-color: #abd155;
        }
        span {
            color: #333;
        }
    }
    }
}
</style>
